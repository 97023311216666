// import { features } from "@sciam/chargebee/constants.json";
import { ChargebeePlanConfig, plans as staticPlans } from "@sciam/chargebee/plans.json";
import { createContext, useContext } from "react";

import { useFlags } from "~core/hooks/use-flags";
import { useUser } from "~features/user";

interface PlanContext {
  plan?: ChargebeePlanConfig | null;
  couponCode?: string;
  customFields?: Record<string, any>;
  eligibility?: PlanPurchaseEligibility;
}
const PlanContext = createContext<PlanContext>({});

export function usePlan() {
  return useContext(PlanContext);
}

interface PlanProviderProps extends PlanContext {
  planId: string;
  children: React.ReactNode;
}
export function PlanProvider({ planId, couponCode, customFields, children }: PlanProviderProps) {
  const plan = staticPlans.find((plan) => plan.id === planId);

  // Get the user's current plan
  const currentPlan = useCurrentPlan();
  // Determine whether the user is eligible to checkout this plan
  const eligibility = getPurchaseEligibility(plan, currentPlan);

  return (
    <PlanContext.Provider
      value={{
        plan,
        couponCode,
        customFields,
        eligibility,
      }}
    >
      {children}
    </PlanContext.Provider>
  );
}

interface PlanPurchaseEligibility {
  /** User is eligible to purchase this plan */
  isPurchasable: boolean;
  /** The product is enabled for checkout from Chargebee's dashboard */
  enabledForCheckout: boolean;
  /** User already has a plan with the same exact price_id */
  userAlreadyHasPlan: boolean;
  /**
   * User already has a plan with the same legacy_resource_id (equivalent Piano term)
   * or equivalent feature set
   */
  userHasSimilarPlan: boolean;
  /**
   * Whether the user is blocked from purchasing this plan due to gift checkout restrictions
   * @TODO: Gift checkout is disabled for launch. Restore this when we're ready
   */
  blockedGiftCheckout: boolean;
  /** Whether the user is blocked from purchasing trial plans */
  blockedTrialCheckout: boolean;
  /**
   * Whether the plan is an objective downgrade from the user's current plan,
   * i.e. the user will not gain any features
   */
  isDowngrade?: boolean;
  /**
   * Whether the plan is an objective upgrade from the user's current plan
   * i.e. the user will not lose any current features
   */
  isUpgrade?: boolean;
}
export const getPurchaseEligibility = (
  plan: ChargebeePlanConfig | null,
  currentPlan: ChargebeePlanConfig | null,
): PlanPurchaseEligibility => {
  const flags = useFlags();

  /** Whether we should compare this plan to the user's current one */
  const canEvaluatePlans = !!plan && !!currentPlan && !plan.is_giftable;

  // @TODO: Gift checkout is disabled for launch. Restore this when we're ready
  const blockGiftCheckout = plan?.is_giftable
    ? import.meta.env.PUBLIC_GIFTS !== "1" && !flags?.gifts
    : false;

  const blockAllCheckouts = import.meta.env.PUBLIC_CHECKOUTS === "0" && !flags?.checkouts;

  /** Block existing customers from purchasing trial plans regardless of their subscription's status */
  const blockTrialPlansForExistingCustomers = !!currentPlan && plan?.is_trial;

  /** Whether checkout will even work for this plan regardless of the user's state */
  const enabledForCheckout =
    !!plan && plan.enabled_for_checkout && !blockGiftCheckout && !blockAllCheckouts;

  /** Whether the user already has this exact plan */
  const userAlreadyHasPlan = canEvaluatePlans && currentPlan.id === plan.id;

  // Check if the plan is an upgrade or downgrade
  const isUpgrade =
    canEvaluatePlans &&
    !userAlreadyHasPlan &&
    plan?.features?.length > currentPlan?.features?.length &&
    currentPlan?.features?.every((feature) => plan?.features?.includes(feature));

  const isDowngrade =
    canEvaluatePlans &&
    !userAlreadyHasPlan &&
    plan?.features?.length < currentPlan?.features?.length &&
    plan?.features?.every((feature) => currentPlan?.features?.includes(feature));

  const userHasSimilarPlan =
    canEvaluatePlans &&
    // Check if the plans match by legacy_resource_id (Piano term)
    currentPlan?.legacy_resource_id === plan.legacy_resource_id;

  return {
    isPurchasable: canEvaluatePlans
      ? enabledForCheckout &&
        !userAlreadyHasPlan &&
        !userHasSimilarPlan &&
        !blockTrialPlansForExistingCustomers
      : enabledForCheckout,

    // These can be used to display reasons why a plan is not purchasable
    enabledForCheckout,
    userAlreadyHasPlan,
    userHasSimilarPlan,
    blockedTrialCheckout: blockTrialPlansForExistingCustomers,

    // @TODO: Remove when we're ready to enable gift checkout
    blockedGiftCheckout: blockGiftCheckout,

    // These can be used to highlight the plan as an upgrade or downgrade
    isUpgrade,
    isDowngrade,
  };
};

/**
 * Get the user's current plan based on their active subscription(s)
 *
 * @TODO: Figure out whether this is better placed in useUser
 * @param {Object} options
 * @param {boolean} options.strict If true, only match the primary Customer, i.e. the Customer a checkout modal will use. Default: true
 * @param {boolean} options.includeCancelled If true, allow matching cancelled subscriptions. Default: false
 */
export function useCurrentPlan({ strict = true, includeCancelled = false } = {}) {
  const { plans, primaryActiveSubscription, primarySubscriptions, activeSubscriptions } = useUser();

  const userSubscription =
    primaryActiveSubscription ||
    (strict && activeSubscriptions[0]) ||
    (includeCancelled && primarySubscriptions[0]) ||
    null;

  if (!userSubscription) return null;

  return plans.find((plan) => plan.sub_id === userSubscription.id) || null;
}
